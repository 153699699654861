import 'react-dates/initialize';

import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import { START_DATE, END_DATE } from 'react-dates/constants';
import DayPickerRangeController from 'react-dates/lib/components/DayPickerRangeController';
import DateRangePickerInputController from 'react-dates/lib/components/DateRangePickerInputController';
import getCalendarMonthWidth from 'react-dates/lib/utils/getCalendarMonthWidth';

import './DatePicker.scss';

export {
  DateRangePicker,
  START_DATE,
  END_DATE,
  DayPickerRangeController,
  DateRangePickerInputController,
  getCalendarMonthWidth,
};
