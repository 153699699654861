import React from 'react';
import PropTypes from 'prop-types';
import canUseDom from 'can-use-dom';

import ReactPlacesAutocomplete from 'react-places-autocomplete';

const getSessionToken = () => (
  canUseDom
    ? new google.maps.places.AutocompleteSessionToken()
    : undefined
);

class PlacesAutocompleteSession extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionToken: getSessionToken(),
    };
  }

  handleSelect = (address, placeId) => {
    // Reset the session token after each selection
    this.setState({ sessionToken: getSessionToken() }, () => {
      this.props.onSelect(address, placeId);
    });
  };

  render() {
    return (
      <ReactPlacesAutocomplete
        debounce={0}
        {...this.props}
        onSelect={this.handleSelect}
        searchOptions={{
          ...this.props.searchOptions,
          sessionToken: this.state.sessionToken,
        }}
      />
    );
  }
}

PlacesAutocompleteSession.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default PlacesAutocompleteSession;
