import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';

import { Button } from '@rvshare/core-ui';
import withHitchTheme from 'core/withHitchTheme';

import { DateRangePicker, START_DATE } from 'components/DatePicker';
import { useDevice }  from 'core/hooks/useDevice';

const DateRangePickerInput = React.forwardRef((props, ref) => {
  const { name, className, type, onChange, customArrowIcon, customInputIcon, isOutsideRange } = props;

  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = useCallback(newValue => onChange(name, newValue), [name, onChange]);

  const handleClearClick = useCallback(() => {
    // Clear resets focus to startDate input
    setFocusedInput(START_DATE);

    handleDatesChange({ startDate: null, endDate: null });
  }, [handleDatesChange]);

  const { startDate, endDate } = props.value || {};

  const showClearButton = !!(startDate || endDate);

  const { isMobile } = useDevice();

  return (
    <div className={cx(`rvshare-DatePicker ${type}__dates`, className)} ref={ref}>
      <DateRangePicker
        startDate={startDate ? moment(startDate) : null}
        startDateId="start_date_search"
        endDate={endDate ? moment(endDate) : null}
        endDateId="end_date_search"
        startDatePlaceholderText="Pick up"
        endDatePlaceholderText="Drop off"
        isOutsideRange={isOutsideRange}
        focusedInput={focusedInput}
        onDatesChange={handleDatesChange}
        onFocusChange={setFocusedInput}
        renderCalendarInfo={() => (
          <div className="rvshare-DatePicker__info">
            {showClearButton && (
              <Button variant="secondary" type="button" onClick={handleClearClick}>
                Clear
              </Button>
            )}
          </div>
        )}
        transitionDuration={0}
        anchorDirection="right"
        customArrowIcon={customArrowIcon || null}
        customInputIcon={customInputIcon || null}
        screenReaderInputMessage="Press the down arrow key to interact with the calendar and select a date."
        hideKeyboardShortcutsPanel
        readOnly
        displayFormat={isMobile ? "MM/DD/YY" : "MM/DD/YYYY"}
      />
    </div>
  );
});

DateRangePickerInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    startDate: PropTypes.any,
    endDate: PropTypes.any,
  }),
};

DateRangePickerInput.defaultProps = {
  className: null,
  onChange: null,
  value: {},
};

export default withHitchTheme(DateRangePickerInput);
