import React from 'react';
import { ThemeProvider } from 'styled-components';
import { hitchTheme } from '@rvshare/styled-theme';

const transparentButtonStyles = {
  backgroundColor: 'transparent',
  color: '#0a2239',
  border: '1px solid transparent',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: 'transparent',
    color: hitchTheme.colors.primary,
  },
  '&:focus': {
    backgroundColor: 'transparent',
    outline: 'none',
  },
  '&:active': {
    backgroundColor: 'transparent',
    color: hitchTheme.colors.primary,
  },
  '&.dropdown-open': {
    color: hitchTheme.colors.primary,
  },
};

export default BaseComponent => props => (
  <ThemeProvider
    theme={{
      ...hitchTheme,
      buttons: { ...hitchTheme.buttons, transparent: transparentButtonStyles },
    }}
  >
    <BaseComponent {...props} />
  </ThemeProvider>
);
